<template>
	<div>
		<navigation>
			<navigation-link url="/procedures/embarquement"
				>Embarquement</navigation-link
			>
			<navigation-link url="/procedures/debarquement"
				>Débarquement</navigation-link
			>
			<navigation-link url="/procedures/franchise">Avantages et Facilités</navigation-link>
		</navigation>
	</div>
</template>

<script>
import Navigation from "../Navigation.vue";
import NavigationLink from "../NavigationLink.vue";
export default {
	components: { NavigationLink, Navigation },
};
</script>