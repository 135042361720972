<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center p-3">
            <h1 class="text-success font-weight-bold page-title">
              Avantages et Facilités
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
           <b-card
            img-src="@/assets/images/equipements/04.jpg"
            img-alt="Image embarquement- SOBEMAP"
            border-variant="light" bg-variant="light"
          ></b-card>
        </b-col>
      </b-row>
    </b-container>


    <b-container class="p-5">
      <b-row>
        <b-col sm="8" lg="8" xl="8">
          <div class="text-justify">
            <p>
            La Société Béninoise des Manutentions Portuaires (SOBEMAP) est une société d’État à caractère commercial et industriel dotée d’une autonomie financière et forte d’une expérience de plus de <strong>52 ans</strong> sur la plate-forme portuaire de Cotonou. Trois domaines d'expertises sont concernés : <strong>la manutention, le transit (port, terrestre et Aéroport) et la consignation maritime.</strong>
            </p>
            <p>
            La SOBEMAP est certifiée ISO 9001 Version 2015 et s’engage à l’élargir à ISO 45001 version 2018. 
            </p>
          </div>

          <h6 class="font-weight-bold text-uppercase">
            LES AVANTAGES OFFERTS AUX CLIENTS
          </h6>

          <div>
            <ul>
              <li>Tarifs souples et très compétitifs;</li>
              <li>Facilitations des enlèvements directs;</li>
              <li>Possibilité de stockage temporaire des marchandises dans les
magasins-cales et terre-pleins avec une franchise en fonction de la
destination ou de la provenance des marchandises;</li>
              <li>Célérité des opérations de manutention;</li>
              <li>Exécution des opérations de bout en bout du navire jusqu’au magasin
du réceptionnaire au Bénin et/ou dans les pays sans façade maritime;</li>
              <li>Sécurisation et assurance de vos marchandises sur nos espaces;</li>
              <li>Respect des exigences en matière d’Hygiène et environnementale.</li>
            </ul>
          </div>
        </b-col>
        <b-col sm="1" lg="1" xl="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col sm="4" lg="3" xl="3" class="text-justify">
          <procedures-nav/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProceduresNav from '../../../containers/website/Navigation/ProceduresNav'
export default {
  components: { ProceduresNav },
    
}
</script>