<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">

            <h1 class="text-success font-weight-bold pt-3 page-title">
              Consignation maritime
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-5 ">
      <b-row>
        <b-col sm="12" lg="8" md="12" >
          <p class="text-justify">
            La SOBEMAP assure également la représentation des armements au Port de
            Cotonou en sa qualité de consignataire maritime. Dans ce cadre, elle prépare
            l’escale des navires, veille au bon déroulement des opérations pendant leur
            séjour à quai au port, réceptionne les factures et s’assure de leur exactitude afin
            de clôturer les comptes d’escale.
</p>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 ">
          <prestations-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PrestationsNav from "../../../containers/website/Navigation/PrestationsNav";
export default {
  components: { PrestationsNav },
};
</script>