<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">
            <h1 class="text-success font-weight-bold pt-5 page-title">
              Mot de la Direction de la SOBEMAP
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5">
      <b-row>
        <b-col sm="8">
          <b-row>
            <b-col>
              <p class="text-justify font-weight-bold">
                Mesdames, Messieurs,
              </p>
              <p class="text-justify font-weight-bold">
                Très chers clients et partenaires de la SOBEMAP,
              </p>
              <p class="text-justify">
                C’est avec un réel plaisir que nous vous souhaitons la bienvenue sur le site web de la Société
                Béninoise des Manutentions Portuaires (SOBEMAP).
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
            <p class="">
              Durant plus de cinq décennies, la Société Béninoise des Manutentions Portuaires
              (SOBEMAP), votre société, est à votre service sur la plateforme portuaire de Cotonou en
              vous offrant les prestations de:
              <ul>
                <li>manutentions portuaires ;</li>
                <li>consignation maritime ;</li>
                <li>transit (port, aéroport et terrestre).</li>
              </ul>
            </p>
            <p class="text-justify">
              Notre vision, c’est d’être le partenaire de référence des acteurs de la chaîne
              d’approvisionnement au Bénin, dans la sous-région et en Afrique. Ceci n’est possible que
              grâce à notre savoir-faire, notre engagement et notre détermination. </p>
            <p class="text-justify">
              Nous réitérons notre promesse de vous offrir, au port de Cotonou, des prestations aux
              meilleurs standards internationaux. Pour y parvenir, des réformes ont été opérées à
              l’interne pour une meilleure organisation, le personnel est formé, recyclé et certains
              certifiés aux normes internationales puis des investissements en matériels et équipements
              de manutention ont été actés. </p>
            <p>Mes chers clients et partenaires,</p>
            <p class="text-justify">
              Mon personnel et moi-même sommes grés de la confiance placée en la SOBEMAP et nous
              vous réaffirmons notre disponibilité à vous écouter et vous satisfaire davantage au
              quotidien. Notre savoir-faire sur la plateforme portuaire de Cotonou, notre histoire, notre
              expérience, notre prise d’initiative et nos certificats ISO 9001 version 2015, et 45001
              version 2018 sont autant de raison pour croire en nous.
            </p>
            <p>Elle se prépare encore pour sa certification en environnement ISO 14001 version 2015.</p>
            <p class="text-justify">
              Je voudrais enfin, vous rassurer du professionnalisme de mon équipe à toujours vous
              accompagner et vous conseiller dans vos opérations de manutention, de consignation
              maritime et de transit.

            </p>
            <p>
              Pour plus de challenges, je souhaite que notre collaboration soit pérenne et prospère.
              <span class="font-weight-bold">SOBEMAP, La Force d’un Partenaire !</span>
            </p>
              </b-col>
          </b-row>
          <p class="text-right">
            <span class="font-weight-bold border-bottom border-success">La Direction</span>
          </p>
          <b-row>
            <b-col>
              <p>
                Bonne navigation à vous sur notre site <a href="https://www.sobemap.com">www.sobemap.com</a>.
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <sobemap-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SobemapNav from '../../../containers/website/Navigation/SobemapNav';
export default {
  components: { SobemapNav },
  name: "MotDirecteur",
};
</script>

<style lang="scss" scoped>
h1 {
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-bottom: 3px solid #006435;
  }
}
</style>
