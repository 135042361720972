<template>
  <div class="sobemap">

    <div class="container">
			<b-row align-h="center">
				<div class="p-5 text-center">
					<h1 class="text-success text-uppercase font-weight-bold page-title">Actualités</h1>
				</div>
			</b-row>
		</div>

    <div class="container">
			<b-row align-h="center" class="flex flex-md-row align-items-center justify-content-center flex-sm-column">
        <b-col sm="4" class="p-2">
          <b-link to="/actualites/sobemap" class="card-link">
          <b-card
            img-src="@/assets/images/prestation/logo_sobemap.jpg"
            img-alt="Image"
            img-top
            img-width="auto"
            img-height="auto"
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                SOBEMAP
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>

				<b-col sm="4" class="p-2">
          <b-link to="/actualites/prevision-navire" class="card-link">
          <b-card
            img-src="@/assets/images/prestation/logo_sobemap.jpg"
            img-alt="Image"
            img-top
            img-width="auto"
            img-height="auto"
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                Prévision des navires
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>

        <b-col sm="4" class="p-2">
          <b-link to="/actualites/appels-offres" class="card-link">
          <b-card
            img-src="@/assets/images/prestation/logo_sobemap.jpg"
            img-alt="Image"
            img-top
            img-width="auto"
            img-height="auto"
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                Appels d'offres
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>

        <b-col sm="4" md="4" class="p-2">
          <b-link to="/actualites/liens-utiles" class="card-link">
          <b-card
            img-src="@/assets/images/prestation/logo_sobemap.jpg"
            img-alt="Image"
            img-top
            img-width="auto"
            img-height="auto"
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                Liens utiles
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>

			</b-row>
		</div>

<!-- <div class="card-container offset-md-1" >
   
      <b-container>
           <b-row>
                <b-col md="8" offset-md="4">
            <vs-card>
              <template #img>
                <img src="@/assets/images/logoSobemap.png" alt="" />
              </template>
              <template #text>
                <br />
                <br />
                <h4 class="text-center ">Liens Utiles</h4>
              </template>
            </vs-card>
          </b-col>
        </b-row>
      </b-container>
   
</div> -->
      
  </div>
</template>

<script>
export default {
  name: "Sobemap",
};
</script>

<style lang="scss" scoped>
.container {
	justify-content: center;
	align-items: center;
}

.bottom-left {
  color: white;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 30%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  background-color: #006435;
}

.card-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>