<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">

            <h1 class="text-success font-weight-bold pb-3 pt-3 page-title">
              Equipements
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col sm="12">
          <b-carousel
            id="carousel-fade"
            controls
            style="text-shadow: 0px 0px 2px #000"
            fade
            img-width="1024"
            img-height="480"
          >
            <b-carousel-slide
              img-src="@/assets/images/equipements/01.jpg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="@/assets/images/equipements/02.jpg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="@/assets/images/equipements/03.jpg"
            ></b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="p-5">
      <b-row>
        <b-col sm="8">
          <p class="text-justify">
            Pour une éfficacité dans l'accomplissement des différentes
            prestations de service, la SOBEMAP a à sa disposition des
            équipements adéquats à la pointe de la technologie.
          </p>
          <p class="text-justify">Il s'agit notamment des :</p>
          <ul class="text-justify">
            <li>
              <span class="font-weight-bold">Engins de levage pour le trafic Conventionnels</span>
                <ul>
                  <li>Chariots élévateurs à fourches de 3T</li>
                  <li>Grues à flèches télescopiques de 4T</li>
                </ul>
            </li>
            <br>
            <li>
              <span class="font-weight-bold">Engins de levage pour le Trafic Conteneurs</span>
                <ul>
                  <li>Chariots élévateurs à fourches de 8T</li>
                  <li>Chariots élévateurs à fourches de 10T</li>
                  <li>Chariots élévateurs à fourches de 12T</li>
                  <li>Chariots élévateurs à spreader à prise frontale de 18T</li>
                  <li>Chariots élévateurs super stacker de 45T</li>
                </ul>
            </li>
            <br>
            <li>
              <span class="font-weight-bold">Engins de Traction</span>
                <ul>
                  <li>Tracteurs de quai et tracteurs RORO de 25 T</li>
                  <li>Remorques de 35 T et de 60T</li>
                  <li>Tracteurs pousseurs pour Véhicules d’occasion de 2,5T</li>
                  <li>Tracteurs Agricoles pour Véhicules d’occasion de 2,5T</li>
                </ul>
            </li>
            <br>
            <li>
              <span class="font-weight-bold">Equipements spéciaux</span>
                <ul>
                  <li>Chargeuses à godet de 16 T</li>
                  <li>Chargeuses à godet de 26 T</li>
                  <li>Pelle excavatrice de 22T</li>
                  <li>Bennes preneuses de 5m3</li>
                  <li>Trémies</li>
                  <li>Palonniers de 20T et de 40T</li>
                </ul>
            </li>
          </ul>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <moyens-techniques-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MoyensTechniquesNav from "../../../containers/website/Navigation/MoyensTechniquesNav";
export default {
  components: { MoyensTechniquesNav },
};
</script>
<style lang="scss" scoped>
::v-deep .carousel{
  margin-left: 0px;
  margin-right: 0px;
}
</style>