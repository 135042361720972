<template>
  <div class="sobemap">

    <div class="container">
			<b-row align-h="center">
				<div class="p-5">
          <div class="text-center">
            <h1 class="text-success font-weight-bold page-title">MOYENS TECHNIQUES</h1>
          </div>
					<p class="mt-4 text-justify">
						Un Bref aperçu des espaces, engins dont dispose la SOBEMAP pour le bon déroulement des travaux.
					</p>
				</div>
			</b-row>
		</div>

    <div class="container">
			<b-row align-h="center">
				<b-col sm="4" class="p-2">
          <b-link to="/resources/infrastructures">
          <b-card
            img-src="@/assets/images/infrastructures/home.jpg"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 25rem;"
            img-height="300"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                Infrastructures
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>

        <b-col sm="4" class="p-2">
           <b-link to="/resources/equipments">
           <b-card
            img-src="@/assets/images/equipements/03.jpg"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 25rem;"
            img-height="300"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h4">
                Equipements
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
				</b-col>


			</b-row>
		</div>
      
  </div>
</template>

<script>
export default {
  name: "Sobemap",
};
</script>


<style lang="scss" scoped>
.container {
	justify-content: center;
	align-items: center;
}

.bottom-left {
  color: white;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 30%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  background-color: #006435;
}
</style>