<template>
    <div>
        <navigation>
            <navigation-link url="/actualites">ACTUALITES</navigation-link>
            <navigation-link url="/actualites/sobemap"
              >La SOBEMAP</navigation-link
            >
            <navigation-link url="/actualites/prevision-navire"
              >Prévision des navires</navigation-link
            >
            <navigation-link url="/actualites/appels-offres">Appels d'offres</navigation-link>
            <navigation-link url="/actualites/demande-cotation">Demande de Cotation</navigation-link>
            <navigation-link url="/actualites/liens-utiles">Liens utiles</navigation-link>
          </navigation>
    </div>
</template>

<script>
import Navigation from "../Navigation.vue";
import NavigationLink from "../NavigationLink.vue";
export default {
  components: { NavigationLink, Navigation },
 
};
</script>