<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="p-5 text-center">
            <h1 class="text-success font-weight-bold page-title">
             Prévision des Navires
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row>
        <b-col sm="12">
        <b-table
          :items="items"
          :fields="fields"
          striped
          hover
          bordered
          :busy="loaded"
          responsive
          show-empty
          empty-text="Aucun navire"
        >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(state)="data">
          {{ getName(data.item.state) }}
        </template>
        <template v-slot:cell(arrival_date)="data">
          {{ data.item.arrival_date | moment("DD/MM/YYYY") }}
        </template>
          
        </b-table>

        <div class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            align="center"
          >
          <template #page="{ page, active }">
            <b v-if="active" class="text-white">{{ page }}</b>
            <i v-else>{{ page }}</i>
          </template>
          </b-pagination>
        </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import ActualitesNav from '../../../containers/website/Navigation/ActualitesNav.vue';
export default {
  // components: { ActualitesNav },

  data() {
    return {
      currentPage: 1,
      totalItems: 0,
      perPage: 20,
      loaded: false,
      items: [],
      fields: [
        {key: 'index', label: "Ordre"},
        {key: 'ship_number', label: "Numéro Navire"},
        {key: 'ship_name', label: "Nom Navire"},
        {key: 'arrival_date', label: "Date d'arrivée"},
        {key: 'ship_consignee.name', label: "Consignataire"},
        {key: 'nature_cargo.name', label: "Nature cargaison"},
        {key: 'type_of_operation.name', label: "Type d'Opération"},
        {key: 'state', label: "Etat"}
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getPrevisions()
      },
    },
    perPage() {
      this.getPrevisions()
    },
    currentPage() {
      this.getPrevisions()
    }
  },
  methods: {
    getName(item) {
      switch (item) {
        case 'MOORING':
          return 'MOUILLAGE'
        case 'DOCKED':
          return 'A QUAI'
        case 'LOADING':
          return 'CHARGEMENT'
        case 'UNLOADING':
          return 'DECHARGEMENT'
        case 'DEPARTURE':
          return 'DEPART'
        case 'DEHALAGE':
          return 'DEHALAGE'
        case 'ENTRY':
          return 'ENTREE'
        case 'PREVISION':
          return 'PREVISION'
        default:
          break
      }
      return 'N/A'
    },
    async getPrevisions() {
      this.loaded = true
      this.items = await this.$http.get(`/api/ship-movement/find-all-paged/${this.currentPage}/${this.perPage}`)
        .then(res => {
          this.loaded = false
          this.totalItems = res.data.total_count
          return res.data.items
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep table thead tr {
  background-color: #006435;
  color: white;
}
::v-deep table tbody tr:hover {
  background-color: #e6f6ed;
}
 ::v-deep .b-pagination .page-item.active .page-link {
	background-color: #006435;
  border-color: #006435;
  outline: none
  }
</style>