<template>
  <div id="home">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <div class="slide-text">
            <div class="title h2">
              <b-link to="/prestations/manutention"> <span class="text-white">Manutention portuaire</span></b-link>
            </div>

            <!-- <div class="subtitle d-none d-xl-block">
                  <p>La manutention portuaire est une activité qui consiste à manipuler les marchandises à l’aide des moyens et équipements adaptés aux opérations import et export. Elle s’exécute à bord des navires, dans les magasins, à quai et sur les terre-pleins d’entreposage.</p>
                </div>
                <div class="subtitle d-block d-sm-none">
                  <p>La manutention portuaire est une activité qui consiste à manipuler les marchandises à l’aide des moyens et équipements adaptés aux opérations import et export.</p>
                </div> -->


          </div>


          <div class="bottom-right text-success h3 d-none d-xl-block">
            <b-col sm="12" class="p-0">
              <b-row>
                <b-col class="bg-success text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone15b.png" height="75" />

                  <h5 class="text-white text-uppercase icon">
                    <b-link to="/prestations/manutention">Manutention</b-link>
                  </h5>
                </b-col>

                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone5a.png" height="75" />
                  <h5 class="text-success text-uppercase icon">Consignation</h5>
                </b-col>

                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone21a.png" height="75" />
                  <h5 class="text-success text-uppercase icon">Transit</h5>
                </b-col>
              </b-row>

            </b-col>

          </div>
        </div>
        <div class="swiper-slide slide2">
          <div class="slide-text">
            <div class="title">
              <b-link to="/prestations/consignation"> <span class="">Consignation maritime</span></b-link>
            </div>
            <!-- <div class="subtitle">
                  <div class="subtitle d-none d-xl-block">
                  <p>La SOBEMAP assure également la représentation des armements au Port de Cotonou en sa qualité de Consignataire maritime. Dans ce cadre, elle effectue les opérations de préparation d’escale avant l’arrivée des navires, veille au bon déroulement des activités des navires pendant leur séjour (Escale) au port et enfin, elle procède au bon dénouement de toutes les situations en instance après le départ des navires.</p>
                </div>
                <div class="subtitle d-block d-sm-none">
                  <p>La SOBEMAP assure également la représentation des armements au Port de Cotonou en sa qualité de Consignataire maritime.</p>
                </div>
                  
                </div> -->
          </div>
          <div class="bottom-right text-success h3 d-none d-xl-block">
            <b-col sm="12" class="p-0">
              <b-row>
                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone15a.png" height="75" />

                  <h5 class="text-success text-uppercase icon">Manutention</h5>
                </b-col>

                <b-col class="bg-success text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone5b.png" height="75" />
                  <h5 class="text-white text-uppercase icon">
                    <b-link to="/prestations/consignation">Consignation</b-link>
                  </h5>
                </b-col>

                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone21a.png" height="75" />
                  <h5 class="text-success text-uppercase icon">Transit</h5>
                </b-col>
              </b-row>

            </b-col>

          </div>

        </div>
        <div class="swiper-slide slide3">
          <div class="slide-text">
            <div class="title">
              <b-link to="/prestations/transit"> <span class="text-white">Transit (Port, Aéroport et Terrestre)</span></b-link>
            </div>

            <!-- <div class="subtitle d-none d-xl-block">
                  <p>La SOBEMAP effectue pour le compte de ses clients, les opérations de transit import/export au niveau du port de Cotonou, de l’Aéroport et des autres frontières terrestres de la République du Bénin.</p>
                  <p>A cet effet, elle dispose d’un agrément de l’Administration des Douanes lui conférant la qualité de commissionnaire agréé des Douanes.</p>
                </div>
                <div class="subtitle d-block d-sm-none">
                  <p>La SOBEMAP effectue pour le compte de ses clients, les opérations de transit import/export.</p>
                </div> -->
          </div>
          <div class="bottom-right text-success h3 d-none d-xl-block">
            <b-col sm="12" class="p-0">
              <b-row>
                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone15a.png" height="75" />

                  <h5 class="text-success text-uppercase icon">Manutention</h5>
                </b-col>

                <b-col class="bg-blur text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone5a.png" height="75" />
                  <h5 class="text-success text-uppercase icon">Consignation</h5>
                </b-col>

                <b-col class="bg-success text-center">
                  <b-img class="mt-3" src="@/assets/icons/icone21b.png" height="75" />
                  <h5 class="text-white text-uppercase icon">
                    <b-link to="/prestations/transit">Transit</b-link>
                  </h5>
                </b-col>
              </b-row>

            </b-col>

          </div>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-white d-block d-sm-none"></div>
      <!-- <div class="swiper-button-prev swiper-button-white d-none d-xl-block" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white d-none d-xl-block" slot="button-next"></div> -->
    </div>
    <div class="section2">
      <b-container fluid>
        <b-row>
          <b-col sm="5" offset-md="1" class="p-2">
            <b-img fluid-grow src="@/assets/images/about/01.jpg" alt="Image 1"></b-img>
          </b-col>
          <b-col sm="5" class="p-2">
            <b-card class="director-card h-100">
              <b-card-title class="title">
                <div class="text-success font-weight-bold">
                  Mot de la Direction de la SOBEMAP
                </div>
              </b-card-title>
              <b-col>
                <div>
                  <br />
                  <p class="text-justify">
                    La SOBEMAP est gérée par un Directeur Général qui se charge de l’exécution, du suivi des décisions issues du Conseil d’Administration, du contrôle et de la coordination de toutes les activités de la société. En pleine restructuration depuis le 30 novembre 2022, sa gestion quotidienne est assurée par Monsieur Armand FANDOHAN.

                </p>
                 
                </div>
                <b-button to="/about-us/mot_directeur" variant="outline-success" class="font-weight-bold">Lire la suite
                </b-button>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="mt-5">
      <b-container fluid>
        <b-row align-h="center">
          <b-col sm="12" md="10" xl="10" lg="10">
            <div class="bg-success w-100 procedure">
              <b-col>
                <h2 class="text-center procedure-title title">
                  Les Procédures
                </h2>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid>
        <b-row align-h="center">
          <b-col sm="4" md="3" xl="3" lg="3" class="p-1 h-75" offset-xl="1.5">
            <b-img src="@/assets/images/procedures/embarquement.png" fluid height="10" />
            <div class="bottom-left text-capitalize h2 font-weight-bold cursor" @click="redirectOnEmbarquement">
              embarquement
            </div>
          </b-col>

          <b-col sm="4" md="3" xl="3" lg="3" class="p-1">

            <b-img src="@/assets/images/procedures/debarquement.png" fluid />
            <div class="bottom-left text-capitalize h2 font-weight-bold cursor" @click="redirectOnDebarquement">
              débarquement
            </div>
          </b-col>

          <b-col sm="4" md="3" xl="3" lg="3" class="p-1">

            <b-img src="@/assets/images/procedures/franchise.png" fluid />
            <div class="bottom-left text-capitalize h2 font-weight-bold cursor" @click="redirectOnFranchise">
              Avantages et Facilités
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="cotation mt-5">
      <b-container fluid>
        <b-row align-h="center">
          <b-col sm="12" md="10" xl="10" lg="10">
            <h2 class="text-center font-weight-bold">Demande de Cotation</h2>
            <b-button to="/actualites/demande-cotation" variant="outline-warning"
              class="font-weight-bold text-center mt-3">Envoyer</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <div class="distinctions mt-5">
    <b-container fluid>
      <b-row align-h="center" class="p-2">
        <b-col sm="12" md="10" xl="10" lg="10">
          <div class="text-center">
            <h2 class="font-weight-bold distinction-color p-0">Nos Distinctions</h2>
            <b-img src="@/assets/images/logoAb.png" fluid srcset="" width="150" height="150" />
            <p class="mt-2">La SOBEMAP est certifiée ISO 9001-2015</p>
          </div>

        </b-col>
      </b-row>
    </b-container>
  </div> -->
  </div>
</template>
<script>
import { Swiper, Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
  data() {
    return {}
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Autoplay],
      // Optional parameters
      loop: true,
      effect: 'coverflow',
      parallax: true,
      spaceBetween: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },

      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },

      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },
  methods: {
    redirectOnDebarquement() {
      this.$router.push({ name: 'debarquement' })
    },
    redirectOnEmbarquement() {
      this.$router.push({ name: 'embarquement' })
    },
    redirectOnFranchise() {
      this.$router.push({ name: 'franchise' })
    }
  }
};
</script>
<style lang="scss">
.icon {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 20px;
  line-height: 140%;
}

.bg-blur {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
}

.cursor {
  cursor: pointer,
}

.bottom-right {
  position: absolute;
  border-radius: 20px 0px 20px 20px;
  top: 78%;
  left: 49.9%;

  // bottom: 8px;
  // right: 16px;
  width: 50%;
  z-index: 1;
  height: auto;
  transform: translate(-1%, -5%);
}

.bottom-left {
  color: white;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 30%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  backdrop-filter: blur(2px);
}

.slide-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 60%;
  height: 30%;
  padding: 20px;
  text-align: left;
}

.slide-text .title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 112px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.slide-text .subtitle {
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.slide-text a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.bottom-right a {
  color: rgba(255, 255, 255);
  text-decoration: none;
}


.slide-text a:active,
.slide-text a:focus,
.slide-text a:hover {
  color: #ffde00;
  text-decoration: none;
}


.slide1 {
  height: auto;
  width: 100%;
  background: linear-gradient(137.52deg, rgba(25, 64, 100, 0.5) 43.24%, rgba(137, 172, 200, 0.15) 85.32%, rgba(247, 251, 254, 0) 125.43%), url("../../assets/images/prestation/manutention.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  mix-blend-mode: darken;
  color: #f6f6f6;
  opacity: 1;
}

.slide2 {
  height: auto;
  background: linear-gradient(137.52deg, rgba(2, 39, 69, 0.5) 43.24%, rgba(137, 172, 200, 0.15) 85.32%, rgba(247, 251, 254, 0) 125.43%), url("../../assets/images/prestation/consignation.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  mix-blend-mode: darken;
  color: #f6f6f6;
}

.slide3 {
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/images/prestation/transit.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  color: #f6f6f6;
  opacity: 1;
}

.carousel {
  @media (min-width: 990px) {
    margin-left: 162.484px;
    margin-right: 162.484px;
  }
}

.section2 {
  margin-top: 50px;
}

.director-card {
  background: #f6f6f6;
  border-radius: 20px;
  min-height: 395px;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.director-card .title {
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: 0em;
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    bottom: 0;
    left: 0;
    // transform: translate(-50%, 0);
    border-bottom: 3px solid #006435;
  }
}

.paragraph {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 149%;
  /* or 24px */

  text-align: justify;
  letter-spacing: -0.01em;
  color: #000000;
}

.procedure {
  border-radius: 20px;
}

.procedure-title {
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding: 40px;

  @media (max-width: 990px) {
    padding: 20px;
  }
}

.card-procedure {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;

  @media (min-width: 990px) {
    margin-left: 162.484px;
    margin-right: 162.484px;
  }
}

.cotation {
  margin: 5px;

  @media (min-width: 990px) {
    margin-left: 162.484px;
    margin-right: 162.484px;
  }

  padding: 50px;

  @media (min-width: 990px) {
    padding: 100px;
  }

  height: 230px;
  text-align: center;
  background-image: url("../../assets/images/cotation.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  color: #f6f6f6;
  border-radius: 20px;
}

.distinctions {
  padding: 40px;
  background: #f6f6f6;
  border-radius: 20px;
  margin: 5px;

  @media (min-width: 990px) {
    margin-left: 162.484px;
    margin-right: 162.484px;
  }

  height: 250px;

  @media (max-width: 990px) {
    height: 450px;
  }
}

.distinction-color {
  color: #4A4A4A;
  font-style: normal;
  font-weight: 800;

}
</style>
