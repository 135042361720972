<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center p-5">
            <h1 class="text-success font-weight-bold page-title">
              Demande de cotation
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="p-5">
      
      <b-row>
        <b-col sm="8 text-justify">
        <b-card border-variant="white" bg-variant="white">
        <b-form @submit.prevent="addCotation" class="p-1">
            <h4 class="font-weight-bold p-2 my-3">Vos Cordonnées</h4>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.name.$model"
                    :formatter="formatter"
                    placeholder="Nom"
                    size="md"
                    :class="{ 'is-invalid': $v.form.name.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.name.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.name.required"
                    >Le nom est requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.surname.$model"
                    placeholder="Prénoms"
                    :formatter="formatter"
                    size="md"
                    :class="{ 'is-invalid': $v.form.surname.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.surname.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.surname.required"
                    >Le(s) prénom(s) est(sont) requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.email.$model"
                    placeholder="Email"
                    :formatter="toLower"
                    type="email"
                    :class="{
                      'is-invalid': $v.form.email.$error
                    }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.email.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.email.required"
                    >L'email est requis.</span>
                    <span
                      v-if="!$v.form.email.email"
                    >L'email doit être valide.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <vue-phone-number-input
                    v-model.trim="form.phone"
                    size="sm"
                    valid-color="#006435"
                    :dark="dark"
                    dark-color="transparent"
                    :translations="translate"
                    error-color="red"
                    color="#006435"
                    :fetch-country="true"
                    :border-radius="4"
                    :required="true"
                    @update="update($event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.society.$model"
                    :formatter="formatter"
                    placeholder="Société"
                    size="md"
                    :class="{ 'is-invalid': $v.form.society.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.society.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.society.required"
                    >La dénomination de la societe est requise.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    id="input-1"
                    v-model="$v.form.country.$model"
                    :formatter="formatter"
                    placeholder="Pays"
                    size="md"
                    :class="{ 'is-invalid': $v.form.country.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.country.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.country.required"
                    >Le pays est requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.town.$model"
                    placeholder="Ville"
                    :formatter="formatter"
                    size="md"
                    :class="{ 'is-invalid': $v.form.town.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.town.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.town.required"
                    >La ville est requise.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            
            <h4 class="font-weight-bold p-2 my-3">Cotation</h4>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <b-form-select
                    v-model="$v.form.type.$model"
                    :options="operationOptions"
                    size="md"
                    :class="{ 'is-invalid': $v.form.type.$error }"
                  ></b-form-select>
                  <div
                    v-if="$v.form.type.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.type.required"
                    >Le type d'opération est requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-select
                    v-model="$v.form.navire_type.$model"
                    :options="shipNatureOptions"
                    size="md"
                    :class="{ 'is-invalid': $v.form.navire_type.$error }"
                  ></b-form-select>
                  <div
                    v-if="$v.form.navire_type.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.navire_type.required"
                    >Le type de navire est requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <b-form-input
                    v-model="$v.form.merchandise.$model"
                    placeholder="Nature marchandise"
                    :formatter="formatter"
                    size="md"
                    :class="{ 'is-invalid': $v.form.merchandise.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.merchandise.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.merchandise.required"
                    >La nature de la marchandise est requise.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group description="Le poids est exprimé en Tonne.">
                  <b-form-input
                    v-model="$v.form.size.$model"
                    placeholder="Tonnage marchandise"
                    type="tel"
                    size="md"
                    :class="{ 'is-invalid': $v.form.size.$error }"
                  ></b-form-input>
                  <div
                    v-if="$v.form.size.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.form.size.required"
                    >Le poids est requis.</span>
                    <span
                      v-if="!$v.form.size.minValue"
                    >La valeur minimale est 1T.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <br>
            <div class="text-center w-100 mt-1 mb-2" v-show="loaded">
              <b-spinner variant="success" />
            </div>
            <b-button type="submit" variant="outline-success" class="w-100" :disabled="loaded"
              >Envoyer</b-button
            >
            <br>
          </b-form>
          </b-card>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4">
          <actualites-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email, minValue } from 'vuelidate/lib/validators'
import ActualitesNav from "../../../containers/website/Navigation/ActualitesNav.vue";
export default {
  components: { ActualitesNav },
  data() {
    return {
      translate: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :'
      },
      operationList: [],
      shipNatureList: [],
      options: [
        { value: null, text: "Choisis une option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      loaded: false,
      form: {
        email: null,
        name: null,
        surname: null,
        phone: null,
        phoneNumber: null,
        merchandise: null,
        society: null,
        town: null,
        country: null,
        type: null,
        size: null,
        navire_type: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      name: {
        required
      },
      merchandise: {
        required
      },
      surname: {
        required
      },
      phone: {
        required
      },
      society: {
        required
      },
      town: {
        required
      },
      country: {
        required
      },
      type: {
        required
      },
      size: {
        required,
        minValue: minValue(1)
      },
      navire_type: {
        required
      },
    }
  },
  computed: {
    dark() {
      if (window.localStorage.getItem('vuexy-skin') === 'dark') return true
      return false
    },
    operationOptions() {
      const options = this.operationList
      const data = [
        { value: null, text: 'Choisir le type d\'opération' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
    shipNatureOptions() {
      const options = this.shipNatureList
      const data = [
        { value: null, text: 'Choisir la nature du navire ' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.title,
          }
          return data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllOperations()
        this.getAllShipNature()
      },
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    toLower(value) {
      return value.toLowerCase()
    },
    update(event) {
      if (event.isValid) {
        this.form.phoneNumber = event.formattedNumber
      }
    },
    async getAllOperations() {
      this.operationList = await this.$http.get('/api/quotation-operation-type/find-all')
      .then(res => res.data)
        .catch(() => {
          return []
        })
    },
    async getAllShipNature() {
      this.shipNatureList = await this.$http.get('/api/ship-nature/find-all')
      .then(res => res.data)
        .catch(() => {
          return []
        })
    },
    resetForm() {
      this.form = {
        name: null,
        surname: null,
        email: null,
        country: null,
        town: null,
        phoneNumber: null,
        phone: null,
        merchandise: null,
        type: null,
        navire_type: null,
        size: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    async addCotation() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.loaded = true;
      const data = {
        merchandise_tonnage: this.form.size,
        ship_nature_id: this.form.navire_type,
        operation_type_id: this.form.type,
        merchandise_nature: this.form.merchandise,
        full_name: this.form.name + ' ' + this.form.surname ,
        enterprise: this.form.society,
        phone_number: this.form.phoneNumber,
        email: this.form.email,
        city: this.form.town,
        country: this.form.country
      }
      this.$http
        .post('/api/quotation/add', data)
        .then(() => {
          this.loaded = false
          this.resetForm()
          this.$bvToast.toast(`Demande envoyée avec succès`, {
                title: `Demande de cotation`,
                toaster: 'b-toaster-top-right',
                variant: "success",
                solid: true,
                appendToast: false
              })
        })
        .catch(() => {
          this.loaded = false
            this.$bvToast.toast(`Une erreur est survenue lors de la demande`, {
                title: `Demande de cotation`,
                toaster: 'b-toaster-top-right',
                variant: "danger",
                solid: true,
                appendToast: false
              })
        })
    }
  },
};
</script>