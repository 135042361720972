<template>
  <div>

    <div class="container">
      <b-row class="h-100">
        <div class="p-5">
          <div class="text-center">
            <h1 class="text-success font-weight-bold page-title">NOS PRESTATIONS</h1>
          </div>
          <p class="text-justify mt-4">
            La Société Béninoise des Manutentions Portuaires (SOBEMAP) a pour objectifs d'exécuter au Port de Cotonou
            des opérations de manutention, d'exécuter au Bénin les activités de Commissionnaire en
            Douane agréé, d'excercer toutes activités qui s'y rapportent.
            Ainsi, elle exerce une activité principale et deux secondaires. Il s'agit notamment de :
          </p>
        </div>
      </b-row>
    </div>

    <div class="container">
      <b-row align-h="center">
        <b-col sm="4" class="p-2">
          <b-card img-src="@/assets/images/prestation/manutention.jpg" img-alt="Image" img-top img-width="auto"
            img-height="250" tag="article" style="max-width: 22rem;" class="mb-2 p-2 rounded" bg-variant="white"
            border-variant="light" body-bg-variant="light" body-class="rounded-bottom">
            <b-card-title>
              <div class="d-flex justify-content-start font-weight-bold text-success h3">
                Manutention
              </div>
            </b-card-title>
            <b-card-text class="text-dark">
              Pour toutes vos opérations de manutention au port de Cotonou, la SOBEMAP
              est votre partenaire de référence.
              <div class="mt-2">
                <b-button href="/prestations/manutention" variant="outline-success">Lire plus</b-button>
              </div>
            </b-card-text>
          </b-card>
        </b-col>

        <b-col sm="4" class="p-2">
          <b-card img-src="@/assets/images/prestation/consignation.jpg" img-alt="Image" img-top img-width="auto" img-height="250"
            tag="article" style="max-width: 22rem;" class="mb-2 p-2 rounded" bg-variant="white" border-variant="light"
            body-bg-variant="light" body-class="rounded-bottom">
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-success h3">
                Consignation
              </div>
            </b-card-title>
            <b-card-text class="text-dark text-center">
              La SOBEMAP assure également la représentation des armements au Port de
              Cotonou en sa qualité de consignataire maritime. <div class="mt-2">
                <b-button href="/prestations/consignation" variant="outline-success">Lire plus</b-button>
              </div>
            </b-card-text>
          </b-card>
        </b-col>

        <b-col sm="4" class="p-2">

          <b-card img-src="@/assets/images/prestation/transit.jpg" img-alt="Image" img-top img-width="auto"
            img-height="250" tag="article" style="max-width: 22rem;" class="mb-2 p-2 rounded" bg-variant="white"
            border-variant="light" body-bg-variant="light" body-class="rounded-bottom">
            <b-card-title>
              <div class="d-flex justify-content-end font-weight-bold text-success h3">
                Transit
              </div>
            </b-card-title>
            <b-card-text class="text-dark text-right">
              La SOBEMAP effectue pour le compte de ses clients, les opérations de transit
              import/export au port de Cotonou <div class="mt-2">
                <b-button href="/prestations/transit" variant="outline-success">Lire plus</b-button>
              </div>
            </b-card-text>
          </b-card>
        </b-col>

        <b-col sm="4" class="p-2">
          <b-card img-src="@/assets/images/prestation/logo_sobemap.jpg" img-alt="Image" img-top img-width="auto"
            img-height="auto" tag="article" style="max-width: 22rem;" class="mb-2 p-2 rounded" bg-variant="white"
            border-variant="light" body-bg-variant="light" body-class="rounded-bottom">
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-success h3">
                Demande de cotation
              </div>
            </b-card-title>
            <b-card-text class="text-dark text-center">
              <div class="mt-2">
                <b-button href="/actualites/demande-cotation" variant="outline-success">Faire ma demande</b-button>
              </div>
            </b-card-text>
          </b-card>
          <b-link to="/actualites/demande-cotation">
          </b-link>
        </b-col>


      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Prestation",
};
</script>



<style lang="scss" scoped>
.container {
  justify-content: center;
  align-items: center;
}

.bottom-left {
  color: white;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 30%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  background-color: #006435;
}
</style>