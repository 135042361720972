<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center p-3">
            <h1 class="text-success font-weight-bold page-title">
              Débarquement
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col>
           <b-card
            img-src="@/assets/images/procedures/debarquement-procedure.jpg"
            img-alt="Image debarquement- SOBEMAP"
            border-variant="light" bg-variant="light"
          ></b-card>
        </b-col>
      </b-row>
    </b-container>


    <b-container class="pt-5">
      <b-row>
        <b-col sm="8" lg="8" xl="8">
          <div class="text-justify">
          <p>
            La SOBEMAP reçoit des informations liées aux navires soit par mail ou lors des réunions de placement des navires à la Capitainerie. 72 heures avant l’accostage du navire, les documents sont déposés au Service Planning par le consignataire. Il s’agit de :
          </p>
          <ol>
            <li>Cargo plan</li>
            <li>Manifeste</li>
            <li>Liste de colisage</li>
            <li>Instructions particulières</li>
          </ol>
          <p>
            Sur la base de ces informations, une réunion de préparation d’escale est organisée entre les différents intervenants de la chaine de déchargement. Le navire est affecté à une équipe composée de : un Chef Service Opération Bord, un Chef Section Opération Bord, les Chefs de quai (Côté Bord), un Chef Section Magasin, des magasiniers et commis (Côté Terre). Cette équipe est chargée d’apprêter les espaces, le matériel et la main d’œuvre.
          </p>
          <p>
            Dès l’accostage du navire, cette équipe prend contact avec l’équipage (Chef Officier) et procède aux différentes inspections avant le démarrage des opérations. Les opérations de déchargement commencent qu’après le paiement de la facture stevedoring (Client Comptant) ou sur la base d’une lettre adressée au directeur Général ou au Directeur Commercial et Marketing indiquant la structure qui supportera cette charge (Clients en compte). Les marchandises déchargées sont soit livrées directement aux sous-palan ou rapprochées et gardées dans les magasins cales ou sur les terre-pleins avant leurs livraisons. La livraison par le magasin se fait sur la base de dépôt de documents tels que :
          </p>
          <ol>
            <li>Bordereau des Frais Uniques (BFU)</li>
            <li>Déclaration Douane</li>
            <li>Quittance Douane</li>
            <li>Connaissement</li>
            <li>Etc…</li>
          </ol>
          <p>
            A la fin des opérations, les différents documents générés par l’activité de déchargement sont signés  par le Bord avant le départ du navire. Il s’agit de :
          </p>
          <ol>
            <li>Time Sheet</li>
            <li>Rapports de déchargement</li>
            <li>Etat différentiel</li>
            <li>Etats d’avarie</li>
            <li>Inter change</li>
            <li>Etc...</li>
          </ol>
          </div>
        </b-col>
        <b-col sm="1" lg="1" xl="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col sm="4" lg="3" xl="3" class="text-justify">
          <procedures-nav/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProceduresNav from '../../../containers/website/Navigation/ProceduresNav'
export default {
  components: { ProceduresNav },
    
}
</script>