import Vue from 'vue'

import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://api-backoffice.sobemap.com',
  headers: {
    'Content-Type': 'application/json'
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
