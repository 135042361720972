<template>
	<div>
		<b-container>
			<b-row class="h-100 p-md-5 mt-sm-5">
          <div class="text-center">
            <h1 class="text-success font-weight-bold page-title">
              POLITIQUE QUALITE-SANTE-SECURITE AU TRAVAIL ET ENVIRONNEMENT DE LA
              SOBEMAP
            </h1>
          </div>
			</b-row>
		</b-container>
		<b-container>
      <b-row>
        <b-col sm="8">
          <p>
						Dans la perspective d'améliorer ses performances et de répondre aux
						exigences de ses parties intéressées, la Société Béninoise des
						Manutentions Portuaires (SOBEMAP) exerçant les activités de
						manutentions portuaires, de consignation maritime et de transit
						(port, aéroport et terrestre) met en place sur l'ensemble de ses
						installations au port de Cotonou, un Système de Management Intégré
						Qualité - Sécurité - Environnement (SMI-QSE) selon les normes ISO
						9001 Version 2015, ISO 14001 Version 2015 et ISO 45001 Version 2018.
					</p>
          <p>A cet effet, elle concentre ses efforts sur les axes d'intervention prioritaires ci-après :</p>

          <ul>
            <li><strong> La satisfaction des clients et autres parties intéressées pertinentes </strong>
            </li>
            <br>
            <li><strong> La préservation de la santé et de la sécurité au travail</strong>
            </li>
            <br>
            <li><strong> La protection de l'environnement</strong>
            </li>
          </ul>

          <p>La Direction Générale s'engage à ce que les différentes prestations de la SOBEMAP soient réalisées conformément aux différents axes de cette politique dans le souci de l'amélioration continue et le respect du cadre légal, réglementaire et contractuel.</p>
          <p>La Direction Générale s'assure de la coordination de l'ensemble des actions relatives à la mise en œuvre et à l'amélioration du SMI-QSE puis de la mise à disposition des ressources nécessaires à la formalisation, au pilotage et à l'entretien de la démarche QSE à la SOBEMAP.</p>

          <p>
            <b-media>
              <template #aside>
                <b-img src="@/assets/images/pdf.jpg" height="75"/>
              </template>
              <span class="mt-0 mb-1">Politique QHSE</span> <br>
              <a href="qhse.pdf" download="PolitiqueQHSE-SOBEMAP">Télécharger</a>
            </b-media>
          </p>
          <p>
            <b-media>
              <template #aside>
                <b-img src="@/assets/images/pdf.jpg" height="75"/>
              </template>
              <span class="mt-0 mb-1">Certificat_ISO_45001_V_2018</span> <br>
              <a href="Certificat_ISO_45001_V_2018.pdf" download="Certificat_ISO_45001_V_2018-SOBEMAP">Télécharger</a>
            </b-media>
          </p>
          <p>
            <b-media>
              <template #aside>
                <b-img src="@/assets/images/pdf.jpg" height="75"/>
              </template>
              <span class="mt-0 mb-1">Certificat_ISO_9001_V_2015</span> <br>
              <a href="Certificat_ISO_9001_V_2015.pdf" download="Certificat_ISO_9001_V_2015-SOBEMAP">Télécharger</a>
            </b-media>
          </p>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <SobemapNav />
        </b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import SobemapNav from "../../containers/website/Navigation/SobemapNav";
export default {
  components: { SobemapNav },
};
</script>