<template>
  <div>
        <li class="p-2 font-weight-bold">
          <b-link v-bind:to="url"> <slot></slot></b-link>
        </li>
  </div>
</template>

<script>
export default {
    name: 'navigationLink',
    props :['url']

}
</script>