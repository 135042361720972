<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <h1 class="text-center text-success font-weight-bold p-5">
           Appels d'offres
          </h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="p-5 ">
      <b-row>
        <b-col sm="8 text-justify">
          <div class="text-center" v-if="!loaded && items.length === 0">
            <h2 class="font-weight-bold">Aucune offre en cours. Merci pour la visite</h2>
          </div>
          <div class="text-center" v-if="loaded">
            <b-spinner v-if="loaded" variant="success" />
          </div>
          <b-col v-for="(item, index) in items" :key="index">
            <b-card border-variant="success"
              bg-variant="light" class="">
              <b-card-title>{{ item.title }}</b-card-title>
              <div v-html="item.content" class="text-truncate"></div>
              <div>
                <p>
                  <b-media>
                    <template #aside>
                      <b-img src="@/assets/images/pdf.jpg" height="75"/>
                    </template>
                    <a :href="getPdf(item.filename)" :download="item.title">Télécharger</a>
                  </b-media>
                </p>
              </div>
            </b-card>
          </b-col>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 ">
          <actualites-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ActualitesNav from '../../../containers/website/Navigation/ActualitesNav.vue';

export default {
  components: { ActualitesNav },
  data() {
    return {
      currentPage: 1,
      totalItems: 0,
      perPage: 6,
      loaded: false,
      items: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getOffers()
      },
    },
  },
  methods: {
    getPdf(item) {
      return `https://ap-backoffice.sobemap.com/api/uploaded/${item}`;
    },
    async getOffers() {
      this.loaded = true
      this.items = await this.$http.get(`/api/micro/tender/list-paged/${this.currentPage}/${this.perPage}`)
        .then(res => {
          this.loaded = false
          this.totalItems = res.data.total_count
          return res.data.items
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    }
  }
}
</script>