<template>
  <div>
    <div class="container">
        <b-row align-h="center">
          <div class="p-5 text-center">
            <h2 class="text-success text-uppercase font-weight-bold page-title">Actualités - SOBEMAP</h2>
          </div>
        </b-row>
    </div>

    <div class="container">
      <b-row align-h="center">
        <b-col sm="12">
          <div class="text-center">
            <b-spinner v-if="loaded" variant="success" />
            <div v-if="!loaded && items.length === 0">
                Aucune actualité disponible.
            </div>
          </div>
        </b-col>
        <b-col sm="6" class="p-3" v-for="(item, index) in items" :key="index">
          <b-card :img-src='getImage(item?.media[0]?.name)' img-alt="Actualités" img-top img-width="" img-height="">
            <b-card-title class="font-weight-bold h5 text-justify text-truncate"> <b-icon icon="align-start" /> <b-link :to="{ name: 'SingleNews' , params: { newsId: item.id.uid } }"> {{ item.title }} </b-link></b-card-title>
            <b-card-text class="small text-muted font-weight-bold"> <b-icon icon="calendar2-event" font-scale="1" variant="success" class="mr-1" />{{ item.updated_at | moment("DD/MM/YYYY") }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      totalItems: 0,
      perPage: 6,
      loaded: false,
      items: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getNews()
      },
    },
  },
  methods: {
    getImage(item) {
      return `https://api-backoffice.sobemap.com/api/uploaded/${item}`;
    },
    async getNews() {
      this.loaded = true
      this.items = await this.$http.get(`/api/micro/news/list-paged/${this.currentPage}/${this.perPage}`)
        .then(res => {
          this.loaded = false
          this.totalItems = res.data.total_count
          return res.data.items
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    }
  }
}
</script>
