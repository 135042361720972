<template>
    <div>
        <navigation>
            <navigation-link url="/about-us">LA SOBEMAP</navigation-link>
            <navigation-link url="/about-us/mot_directeur"
              >Mot de la Direction de la SOBEMAP</navigation-link
            >
            <navigation-link url="/about-us/presentation">Contacts clés</navigation-link>
            <!-- <navigation-link url="/about-us/equipe_dirigeante">Equipe dirigeante</navigation-link> -->
            <navigation-link url="/qhse">QHSE</navigation-link>
          </navigation>
    </div>
</template>

<script>
import Navigation from "../Navigation.vue";
import NavigationLink from "../NavigationLink.vue";
export default {
  components: { NavigationLink, Navigation },
 
};
</script>