<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="p-5 text-center">
            <h1 class="text-success font-weight-bold p-5 page-title">
            Liens Utiles
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="p-5 ">
      <b-row>
        <b-col sm="8 text-justify" >
          <div class="text-uppercase">
            <ul>
              <li><a href="https://transports.bj" target="_blank">Ministère des Infrastructures et des Transports</a></li>
              <li><a href="https://guce.gouv.bj" target="_blank">Guichet Unique du Commerce Extérieur</a></li>
              <li><a href="http://portcotonou.com" target="_blank">Port Autonome de Cotonou</a></li>
              <li><a href="https://douanes.gouv.bj" target="_blank">Douane</a></li>
            </ul>
          </div>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 ">
          <actualites-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ActualitesNav from '../../../containers/website/Navigation/ActualitesNav.vue';
export default {
  components: { ActualitesNav },
};
</script>