<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center mb-5">
            <h1 class="text-center text-success font-weight-bold page-title">
              Contacts clés
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <b-card
            img-src="@/assets/images/about/01.jpg"
            img-alt="Card image"
            img-height="600"
            border-variant="light"
            bg-variant="light"
          >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="p-lg-5 pm-md-3 p-sm-2">
      <b-row>
        <b-col sm="8">
          <p class="">
            Sous la tutelle du Ministère du Cadre de Vie et des Transports,
            chargé du Développement Durable, la SOBEMAP est structurée
            comme suit :
          </p>
          <div class="border-bottom border-success">
            <b-card border-variant="light" bg-variant="light">
              <b-row>
                <b-col sm="4" class="p-2 d-flex align-items-center">
                  <b-img-lazy
                    width="500"
                    height="500"
                    rounded="fill"
                    fluid
                    alt="Circle image"
                    src="@/assets/images/logoSobemap.png"
                    class="m1"
                  ></b-img-lazy
                ></b-col>
                <b-col sm="8">
                  <p class="text-right h5">La Direction Générale</p>
                  <p class="text-justify">
                    La SOBEMAP est gérée par un Directeur Général qui se charge
                    de l’exécution, du suivi des décisions issues du Conseil
                    d’Administration, du contrôle et de la coordination de
                    toutes les activités de la société. En pleine
                    restructuration depuis le 30 novembre 2022, sa gestion
                    quotidienne est assurée par Monsieur
                    <span class="font-weight-bold">Armand FANDOHAN</span>.
                  </p>
                  <a href="mailto:frontoffice@sobemap.com"
                    >frontoffice@sobemap.com</a
                  ><br />
                  <a href="tel:+22955996382">+229 55996382</a>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <div class="border-bottom border-success">
            <b-card border-variant="light" bg-variant="transparent">
              <b-row>
                <b-col sm="4" class="p-2 d-flex align-items-center">
                  <b-img-lazy
                    fluid
                    alt="DCM-Sobemap"
                    src="@/assets/images/equipe/DCM.jpg"
                    class="m1"
                  ></b-img-lazy
                ></b-col>
                <b-col>
                  <p class="text-right h5">Sakariahou ALOUGBIN</p>
                  <p class="text-right font-weight-bold text-success">
                    Directeur Commercial et Marketing (DCM)
                  </p>
                  <p class="text-justify">
                    Il élabore et met en œuvre les politiques, plans et
                    stratégies en matière commerciale & marketing pour accroître
                    et pérenniser les ventes de l’entreprise. Par ailleurs, il
                    définit, pilote et contrôle la mise en œuvre des démarches
                    qualité, sécurité, environnement et du développement durable
                    de l’entreprise.
                  </p>
                  <a href="mailto:sakariahou.alougbin@sobemap.com"
                    >sakariahou.alougbin@sobemap.com</a
                  >
                  <br />
                  <a href="tel:+22995566972">+229 95566972</a>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <div class="border-bottom border-success">
            <b-card border-variant="light" bg-variant="transparent">
              <b-row>
                <b-col>
                  <p class="h5">Pascal Abalo COUBEOU</p>
                  <p class="font-weight-bold text-success">
                    Directeur de l'Exploitation (DE)
                  </p>
                  <p class="text-justify">
                    Il est responsable de la stratégie opérationnelle de
                    l’entreprise. Il organise les opérations de manutention
                    portuaire relevant des domaines de compétence de
                    l’entreprise. A ce titre, il est en charge de l’optimisation
                    des marges sur les opérations et participe au développement
                    des activités en anticipant les évolutions du marché.
                  </p>
                  <a href="mailto:pascal.coubehou@sobemap.com"
                    >pascal.coubehou@sobemap.com</a
                  ><br />
                  <a href="tel:+22995360622">+229 95360622</a>
                </b-col>
                <b-col sm="4" class="p-2 d-flex align-items-center">
                  <b-img-lazy
                    fluid
                    alt="DE-Sobemap"
                    src="@/assets/images/equipe/DE.jpg"
                    class="m1"
                  ></b-img-lazy
                ></b-col>
              </b-row>
            </b-card>
          </div>
          <div class="border-bottom border-success">
            <b-card border-variant="light" bg-variant="transparent">
              <b-row>
                <b-col sm="4" class="p-2 d-flex align-items-center">
                  <b-img-lazy
                    fluid
                    alt="DAF-Sobemap"
                    src="@/assets/images/equipe/DAF.jpg"
                    class="m1"
                  ></b-img-lazy
                ></b-col>
                <b-col>
                  <p class="text-right h5">Charlemagne ZOCLI</p>
                  <p class="text-right font-weight-bold text-success">
                    Directeur Administratiif et Financier (DAF)
                  </p>
                  <p class="text-justify">
                    En tant que responsable de la stratégie financière de
                    l’entreprise, il coordonne et supervise principalement la
                    comptabilité, les finances et la fiscalité. Il est garant de
                    la bonne gestion administrative de l’entreprise.
                  </p>
                  <a href="mailto:charlemagne.zocli@sobemap.com"
                    >charlemagne.zocli@sobemap.com</a
                  ><br />
                  <a href="tel:+22995360681">+229 95360681 </a>
                </b-col>
              </b-row>
            </b-card>
          </div>

          <div class="border-bottom border-success">
            <b-card border-variant="light" bg-variant="transparent">
              <b-row>

                <b-col>
                  <p class="text-left h5">HOUNGBO Calixte</p>
                  <p class="text-left font-weight-bold text-success">
                    Chef Département Matériel et Maintenance
                  </p>
                  <p class="text-justify">
                    Il assure la gestion et la maintenance des engins et
                    équipements de manutention de l’entreprise. Il coordonne les
                    activités de confection des matériels et des équipements
                    spéciaux.
                  </p>
                  <a href="mailto:calixte.houngbo@sobemap.com"
                  >calixte.houngbo@sobemap.com</a
                  >
                  <br />
                  <a href="tel:+22995933285">+229 95933285</a>
                </b-col>
                <b-col sm="4" class="p-2 d-flex align-items-center">
                  <b-img-lazy
                      fluid
                      alt="DCM-Sobemap"
                      src="@/assets/images/equipe/CDMM.jpg"
                      class="m1"
                  ></b-img-lazy
                  ></b-col>
              </b-row>
            </b-card>
          </div>
          <p class="font-weight-bold">SOBEMAP, LA FORCE D’UN PARTENAIRE !</p>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <sobemap-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SobemapNav from "../../../containers/website/Navigation/SobemapNav";
export default {
  components: { SobemapNav },
  name: "Contacts clés",
};
</script>
