<template>
  <div>
    <div class="container">
      <b-row class="h-100">
        <b-col>
        <div class="pt-5 text-center">
          <h1 class="text-success font-weight-bold page-title">
            LA SOBEMAP
          </h1>
          <p class="mt-4 text-justify">
            Créer le 27 mars 1969, la Société Béninoise des Manutentions Portuaires (SOBEMAP) est une société commerciale ayant une expérience certaine de plus de cinq décennies sur la plate-forme portuaire de Cotonou.
            Avec un capital de 7.600.000.000 francs CFA, elle met à la disposition de ses partenaires, son expertise en matière de manutention portuaire, de transit port et Aéroport et de consignation maritime ainsi que toutes activités qui s’y rapportent. Elle est certifiée ISO 9001 version 2015 et ISO 45001 version 2018.
            Ses valeurs sont essentiellement : la proximité, l’écoute et l’accompagnement de sa clientèle.
          </p>
          <p class="text-justify">
            Avec son savoir-faire, son histoire, son expérience, ses prises d’initiatives et ses certificats ISO sont autant de raisons pour croire en elle.  
          </p>
        </div>
        </b-col>
      </b-row>
    </div>
    <div class="container">
      <b-row align-h="center" class="flex flex-md-row align-items-center justify-content-center flex-sm-column">
        <b-col sm="4" md="4" lg="4" xl="4" class="p-2">
          <b-link to="/about-us/mot_directeur" class="card-link">
            <b-card
            img-src="@/assets/images/logoSobemap.png"
              img-alt="Image"
              img-top
              img-width="auto"
              img-height="auto"
              tag="article"
              style="max-width: 22rem;"
              class="mb-2 rounded"
              bg-variant="transparent"
              border-variant="light"
              body-bg-variant="success"
              body-class="rounded-bottom"
            >
              <b-card-title>
                <div class="d-flex justify-content-center font-weight-bold text-white h3">
                  Direction Générale
                </div>

              </b-card-title>
            </b-card>
          </b-link>
        </b-col>

        <b-col sm="4" md="4" lg="4" xl="4">
          <b-link to="/about-us/presentation" class="card-link">
          <b-card
            img-src="@/assets/images/logoSobemap.png"
            img-alt="Image"
            img-top
            img-width="auto"
            img-height="auto"
            tag="article"
            style="max-width: 22rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h3">
                Contacts clés
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
        </b-col>

        <b-col sm="4" md="4" class="p-2 card-link">
          <b-link to="/qhse" class="card-link">
          <b-card
            img-src="@/assets/images/logoSobemap.png"
            img-alt="Image"
            img-top
            img-width="50"
            img-height="auto"
            tag="article"
            style="max-width: 22rem;"
            class="mb-2 rounded"
            bg-variant="transparent"
            border-variant="light"
            body-bg-variant="success"
            body-class="rounded-bottom"
          >
            <b-card-title>
              <div class="d-flex justify-content-center font-weight-bold text-white h3">
                QHSE
              </div>
              
            </b-card-title>
          </b-card>
          </b-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sobemap",
};
</script>

<style lang="scss" scoped>
.container {
  justify-content: center;
  align-items: center;
}

.bottom-left {
  color: white;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 30%;
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  background-color: #006435;
}

.card-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>