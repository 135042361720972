<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">
            <h1 class="text-success font-weight-bold pt-3 page-title">
              Manutention portuaire
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-5 ">
      <b-row>
        <b-col sm="8">
          <div class="text-justify">
            Pour toutes vos opérations de manutention au port de Cotonou, la SOBEMAP est votre référence. 
          </div>
          <!-- <p class=" font-weight-bold">La manutention bord</p>
          <p>Encore appelée stevedoring, est l’ensemble des opérations de chargement et de déchargement à bord des navires ;</p>
        
          <p class=" font-weight-bold">La manutention terre</p>
          <p class="">
            Encore appelée acconage, est l’ensemble des opérations de rapprochement, de réception, de garde et de livraison des marchandises dans l’enceinte portuaire
          </p>
          <hr>

          <p class="mt-2">
            Les activités connexes à la manutention sont multiples et sont essentiellement des prestations de service à savoir : le shifting, le gardiennage, le transfert ou le rapprochement de la marchandise, le positionnement et le repositionnement des conteneurs, la location des engins et équipements de manutention, la mise à disposition de la main d’œuvre (le travail en heures supplémentaires), le groupage, le dégroupage et l’empotage des conteneurs, le bâchage, le nettoyage et les petites réparations de conteneurs et autres prestations diverses.
          </p> -->
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 ">
          <prestations-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PrestationsNav from "../../../containers/website/Navigation/PrestationsNav";
export default {
  components: { PrestationsNav },
};
</script>