<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
        <div class="text-center p-3">
          <h1 class="text-success font-weight-bold page-title">
            Embarquement
          </h1>
        </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
           <b-card
            img-src="@/assets/images/procedures/embarquement-procedure.jpg"
            img-alt="Image embarquement- SOBEMAP"
            border-variant="light" bg-variant="light"
          ></b-card>
        </b-col>
      </b-row>
    </b-container>


    <b-container class="p-5">
      <b-row>
        <b-col sm="8">
          <p class="text-justify">
            A l’embarquement des marchandises, des documents essentiels sont déposés à la SOBEMAP par divers clients demandeurs de cette prestation. L’ensemble de ces documents constitue le dossier d’embarquement. Il s’agit de :
          </p>
          <ol>
            <li>Bulletin d’embarquement;</li>
            <li>Déclaration douane;</li>
            <li>Quittance douane;</li>
            <li>Bordereau des Frais Uniques (BFU) réglé;</li>
            <li>Bon de livraison (Trafic en Transbordement).</li>
          </ol>
          <p class="text-justify">
            Tous ces documents à jour génèrent dans le Guichet Unique Portuaire (GUP) un <strong>Bon A Sortie</strong> appelé <strong>BAS</strong> qui formalise l’autorisation d’embarquer une marchandise sur un navire.
          </p>
          <p class="text-justify">
            Ce Bulletin d’embarquement est déposé au Service Informatisation des navires (SDIN) 48 heures au moins avant l’accostage du navire. Dès lors, le client peut se rapprocher du Service Planning de la SOBEMAP pour recevoir l’autorisation ou non de rapprocher sur un terre-plein les marchandises à embarquer.  
          </p>
          <p class="text-justify">
            Quelques heures avant l’accostage du navire, l’équipe opération bord se charge de la reconnaissance des colis à embarquer, étudie le Cargo plan et procède à la demande de la main d’œuvre et des équipements indispensables pour l’embarquement.
          </p>
          <p class="text-justify">
            A l’arrivée du navire, une équipe composée d’un Chef Service Opération Bord, d’un Chef Section Opération Bord et des Chefs Quai procèdent à l’organisation du travail sur le navire en respectant scrupuleusement le plan Cargo et les mesures sécuritaires.
          </p>
          <p class="text-justify">
           A la fin des opérations, le bulletin d’embarquement et les différents rapports sont signés par le Chef Officier.
          </p>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <procedures-nav/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProceduresNav from '../../../containers/website/Navigation/ProceduresNav'
export default {
  components: { ProceduresNav },
    
}
</script>