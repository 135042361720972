import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

// WebSites Pages Component
import Accueil from '../views/website/Accueil'
import Sobemap from '../views/website/Sobemap'
import MoyensTechniques from '../views/website/MoyensTechniques'
import Actualites from '../views/website/Actualites'
import Contact from '../views/website/Contact'
import MotDirecteur from '../views/website/LaSobemap/MotDirecteur'
import Presentation from '../views/website/LaSobemap/Presentation'
import Infrastructures from '../views/website/MoyensTechniques/Infrastructures'
import Equipements from '../views/website/MoyensTechniques/Equipements'
import Manutention from '../views/website/Prestations/Manutention'
import Prestations from '../views/website/Prestations'
import Consignation from '../views/website/Prestations/Consignation'
import Transit from '../views/website/Prestations/Transit'
import AppelsOffres from '../views/website/Actualites/AppelsOffres'
import PrevisionNavire from '../views/website/Actualites/PrevisionNavire'
import DemandeCotation from '../views/website/Actualites/DemandeCotation'
import SingleNews from '../views/website/Actualites/SingleNews'

import LiensUtiles from '../views/website/Actualites/LiensUtiles'
import SobemapActualites from '../views/website/Actualites/Sobemap'
import Embarquement from '../views/website/Procedures/Embarquement'
import Debarquement from '../views/website/Procedures/Debarquement'
import Franchise from '../views/website/Procedures/Franchise'

import Qhse from '../views/website/Qhse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/website/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: Accueil
      },
      {
        path: '/about-us',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/LaSobemap/index.vue'),
        children: [
          {
            path: '/',
            name: 'about-us',
            component: Sobemap

          },
          {
            path: '/about-us/mot_directeur',
            name: 'Mot_Directeur',
            component: MotDirecteur
          },
          {
            path: '/about-us/presentation',
            name: 'Presentation',
            component: Presentation
          },
        ]
      },
      {
        path: '/resources',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/MoyensTechniques/index.vue'),
        children: [
          {
            path: '/',
            name: 'resources',
            component: MoyensTechniques

          },
          {
            path: '/resources/infrastructures',
            name: 'infrastructures',
            component: Infrastructures

          },
          {
            path: '/resources/equipments',
            name: 'equipement',
            component: Equipements

          }
        ]
      },
      {
        path: '/procedures',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Procedures/index.vue'),
        children: [
          {
            path: '/procedures/embarquement',
            name: 'embarquement',
            component: Embarquement

          },
          {
            path: '/procedures/debarquement',
            name: 'debarquement',
            component: Debarquement

          },
          {
            path: '/procedures/franchise',
            name: 'franchise',
            component: Franchise
          }
        ]
      },

      {
        path: '/prestations',
        redirect: '/prestations/all',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Prestations/index.vue'),
        children: [
          {
            path: '/prestations/all',
            name: 'prestations',
            component: Prestations
          },
          {
            path: '/prestations/manutention',
            name: 'Manutention',
            component: Manutention

          },
          {
            path: '/prestations/consignation',
            name: 'consignation',
            component: Consignation

          },
          {
            path: '/prestations/transit',
            name: 'transit',
            component: Transit

          }
        ]
      },
      {
        path: '/actualites',
        component: () => import(/* webpackChunkName: "about" */ '../views/website/Actualites/index.vue'),
        children: [
          {
            path: '/',
            name: 'actualites',
            component: Actualites

          },
          {
            path: '/actualites/appels-offres',
            name: 'AppelsOffres',
            component: AppelsOffres

          },
          {
            path: '/actualites/demande-cotation',
            name: 'DemandeCotation',
            component: DemandeCotation

          },
          {
            path: '/actualites/prevision-navire',
            name: 'PrevisionNavire',
            component: PrevisionNavire

          },
          {
            path: '/actualites/liens-utiles',
            name: 'LiensUtiles',
            component: LiensUtiles
          },
          {
            path: '/actualites/sobemap',
            name: 'SobemapActualites',
            component: SobemapActualites
          },
          {
            path: '/actualites/sobemap/:newsId',
            name: 'SingleNews',
            component: SingleNews
          }
        ]
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact
      },
      {
        path: '/qhse',
        name: 'qhse',
        component: Qhse
      }
    ]
  },
  {
    path: '*'  ,
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
