<template>
    <div>
        <navigation>
           <navigation-link url="/prestations">PRESTATIONS</navigation-link>
            <navigation-link url="/prestations/manutention">Manutention</navigation-link>
            <navigation-link url="/prestations/consignation"
              >Consignation</navigation-link
            >
            <navigation-link url="/prestations/transit"
              >Transit</navigation-link
            >
            
          </navigation>
    </div>
</template>

<script>
import Navigation from "../Navigation.vue";
import NavigationLink from "../NavigationLink.vue";
export default {
  components: { NavigationLink, Navigation },
 
};
</script>