<template>
    <div>
        <navigation>
           <navigation-link url="/resources">MOYENS TECHNIQUES</navigation-link>
            <navigation-link url="/resources/infrastructures">Infrastructures</navigation-link>
            <navigation-link url="/resources/equipments"
              >Equipements</navigation-link
            >
            
          </navigation>
    </div>
</template>

<script>
import Navigation from "../Navigation.vue";
import NavigationLink from "../NavigationLink.vue";
export default {
  components: { NavigationLink, Navigation },
 
};
</script>