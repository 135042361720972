<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">
            <h1 class="text-success font-weight-bold pb-3 pt-3 page-title">
              Infrastructures
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
           <b-carousel
            id="carousel-fade"
            controls
            style="text-shadow: 0px 0px 2px #000"
            fade
            img-width="1024"
            img-height="480"
          >
            <b-carousel-slide
              img-src="@/assets/images/infrastructures/home.jpg"
              img-width="1024"
              img-height="480"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="@/assets/images/infrastructures/infra_2.jpg"
              img-width="1024"
              img-height="480"
            ></b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>


    <b-container class="p-5">
      <b-row>
        <b-col sm="8">
          <p class="text-justify">
            Pour atteindre ses objectifs, la SOBEMAP s'est dotée d'importantes
            infrastructures qui la rendent très compétitive sur le marché. Ces
            infrastructures sont composées de:
          </p>
          <ul>
            <li>Cinq (05) magasins Cales d’une superficie de 18.478 m²;</li>
            <li>Six (06) Terre-pleins d’une superficie de 13.021 m²;</li>
            <li>Un parc à Tampon Unique sur la bande des 200 mètres d’une superficie de 48.452 m²;</li>
            <li>Un espace de 40.000 m² servant de parking aux camions gros porteurs sur la bande des 200m;</li>
            <li>Un espace de 10.000 m² en terre-plein nu sur la bande des 200m;</li>
            <li>Un parc à conteneurs de 67.244 m² dans l’enceinte portuaire;</li>
            <li>Un terre-plein parc à conteneur N°13 de 16.921 m² dans l’enceinte portuaire;</li>
            <li>Un espace de 2ha99a44ca servant de parking camions à Adjagbo dans l’Arrondissement de Glo-Djigbé;</li>
            <li>Un espace de 20ha 22a76ca à Porga au niveau de la frontière avec le Burkina-Faso.</li>
          </ul>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 text-justify">
          <moyens-techniques-nav/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MoyensTechniquesNav from '../../../containers/website/Navigation/MoyensTechniquesNav'
export default {
  components: { MoyensTechniquesNav },
    
}
</script>
<style lang="scss" scoped>
::v-deep .carousel{
  margin-left: 0px;
  margin-right: 0px;
}
</style>