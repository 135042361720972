<template>
	<div class="pt-5">
		<b-container>
			<div class="text-center">
				<b-spinner v-if="loaded" variant="success" />
			</div>
		</b-container>

		<b-container v-if="actuality">
			<b-row align-h="center">
				<div class="p-5 text-center">
					<h2 class="text-success text-uppercase font-weight-bold">
						{{ actuality.title }}
					</h2>
				</div>
				<b-col sm="12">
          <div id="carousel-id">
            <!-- <b-carousel
              :interval="4000"
              controls
              indicators
            >
              <b-carousel-slide v-for="(item, index) in actuality.media" :key="index">
                <template #img>
                  <img
                    class="w-100"
                    :src="getImage(item.name)"
                    fluid-grow
                    alt="Image-Sobemap"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel> -->
          </div>
				</b-col>
			</b-row>
		</b-container>
		<b-container v-if="actuality">
			<b-row class="p-2">
				<b-col sm="8" class="text-justify">
					<b-card bg-variant="transparent" border-variant="light">
						<b-card-text>
							<div v-html="actuality.description" />
						</b-card-text>
						<hr color="#ffde00" />
						<i
							>Publié le
							{{ actuality.limit_date | moment("DD MMM YYYY à HH:mm") }}</i
						>
					</b-card>
				</b-col>
				<b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
				<b-col class="sm-4">
					<ActualitesNav />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import ActualitesNav from "../../../containers/website/Navigation/ActualitesNav.vue";
export default {
	components: { ActualitesNav },
	data() {
		return {
			loaded: false,
			actuality: null,
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.getNew();
			},
		},
	},
	methods: {
		getImage(item) {
			return `https://api-backoffice.sobemap.com/api/uploaded/${item}`;
		},
		async getNew() {
			this.loaded = true;
			this.actuality = await this.$http
				.get(`/api/micro/news/find/${this.$route.params.newsId}`)
				.then((res) => {
					this.loaded = false;
					return res.data;
				})
				.catch(() => {
					this.loaded = false;
					return null;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .carousel{
  margin-left: 0px;
  margin-right: 0px;
}
</style>