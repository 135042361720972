import { render, staticRenderFns } from "./Actualites.vue?vue&type=template&id=756f452e&scoped=true&"
import script from "./Actualites.vue?vue&type=script&lang=js&"
export * from "./Actualites.vue?vue&type=script&lang=js&"
import style0 from "./Actualites.vue?vue&type=style&index=0&id=756f452e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756f452e",
  null
  
)

export default component.exports