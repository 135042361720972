<template>
	<div>
		<div class="container">
			<b-row align-h="center">
				<div class="p-5 text-center">
					<h1 class="text-success text-uppercase font-weight-bold page-title">
						Contacts
					</h1>
				</div>
			</b-row>
		</div>
		<b-container>
			<b-row align-h="center" class="mb-5 border-top border-bottom border-success">
				<b-col sm="3" class="p-2">
					<div class="text-center">
						<p class="h1 mb-2">
							<b-icon icon="geo-alt-fill" variant="success"></b-icon>
						</p>
						<p class="text-justify text-center">
							01 BP 35 Boulevard de la Marina, <br />
							Cotonou, République du Bénin
						</p>
					</div>
				</b-col>

				<b-col sm="3" class="text-center p-2">
					<p class="h1 mb-2">
						<b-icon icon="telephone-fill" variant="success"></b-icon>
					</p>

					<ul class="list-unstyled text-center">
						<li><a href="tel:+22955996382">(229) 55 99 63 82</a></li>
					</ul>
				</b-col>

				<b-col sm="3" class="text-center p-2">
					<p class="h1 mb-2">
						<b-icon icon="envelope-fill" variant="success"></b-icon>
					</p>
					<ul class="list-unstyled text-center">
						<li><a href="mailto:sobemap@sobemap.com">sobemap@sobemap.com</a></li>
						<li><a href="mailto:dircomark@sobemap.com">dircomark@sobemap.com</a></li>
					</ul>
				</b-col>
				<b-col sm="3" class="text-center p-2">
					<p class="h1 mb-2">
						<b-icon icon="globe2" variant="success"></b-icon>
					</p>
					<ul class="list-unstyled text-center">
						<li><a href="https://www.sobemap.com">www.sobemap.com</a></li>
					</ul>
				</b-col>
			</b-row>
			<b-row class="mt-2">
				<b-col sm="5" class="p-2">
					<div class="h-100 d-flex flex-column justify-content-center">
						<div class="align-items-center">
							<b-img src="@/assets/images/about/01.jpg" fluid-grow />
						</div>
					</div>

				</b-col>
				<b-col sm="7" class="p-2">
					<h2 class="text-center text-success">Ecrivez-nous !</h2>
					<b-form @submit.prevent="onSubmit" @reset="onReset">
						<b-row>
							<b-col sm="6">
								<b-form-group>
									<b-form-input v-model="$v.form.name.$model" :formatter="formatter" placeholder="Nom"
										size="md" :class="{ 'is-invalid': $v.form.name.$error }"></b-form-input>
									<div v-if="$v.form.name.$error" class="invalid-feedback">
										<span v-if="!$v.form.name.required">Le nom est requis.</span>
									</div>
								</b-form-group>
							</b-col>

							<b-col sm="6">
								<b-form-group>
									<b-form-input v-model="$v.form.surname.$model" placeholder="Prénoms" size="md"
										:formatter="formatter" :class="{ 'is-invalid': $v.form.surname.$error }">
									</b-form-input>
									<div v-if="$v.form.surname.$error" class="invalid-feedback">
										<span v-if="!$v.form.surname.required">Le(s) prénom(s) est(sont) requis.</span>
									</div>
								</b-form-group>
							</b-col>

							<b-col sm="6">
								<b-form-group>
									<b-form-input v-model="$v.form.email.$model" placeholder="Email" size="md"
										:formatter="toLower" type="email" :class="{
											'is-invalid': $v.form.email.$error,
										}"></b-form-input>
									<div v-if="$v.form.email.$error" class="invalid-feedback">
										<span v-if="!$v.form.email.required">L'email est requis.</span>
										<span v-if="!$v.form.email.email">L'email doit être valide.</span>
									</div>
								</b-form-group>
							</b-col>

							<b-col sm="6">
								<b-form-group>
									<vue-phone-number-input v-model.trim="form.phone" size="sm" valid-color="#006435"
										:dark="dark" dark-color="transparent" :translations="translate"
										error-color="red" color="#006435" :border-radius="4" default-country-code="BJ"
										:required="true" @update="update($event)" />
								</b-form-group>
							</b-col>

							<b-col sm="6">
								<b-form-group>
									<b-form-input v-model="$v.form.object.$model" placeholder="Objet" size="md" required
										:class="{
											'is-invalid': $v.form.object.$error,
										}"></b-form-input>
									<div v-if="$v.form.object.$error" class="invalid-feedback">
										<span v-if="!$v.form.object.required">L'object est requis.</span>
									</div>
								</b-form-group>
							</b-col>

							<b-col sm="6">
								<b-form-group>
									<b-form-select v-model="$v.form.motif.$model" :options="motifOptions"
										placeholder="Mofif" size="md" :class="{
											'is-invalid': $v.form.motif.$error,
										}"></b-form-select>
									<div v-if="$v.form.motif.$error" class="invalid-feedback">
										<span v-if="!$v.form.motif.required">Le motif est requis.</span>
									</div>
								</b-form-group>
							</b-col>
						</b-row>

						<b-form-group>
							<b-form-textarea v-model="$v.form.message.$model" placeholder="Message" no-resize rows="5"
								max-rows="5" :class="{
									'is-invalid': $v.form.message.$error,
								}"></b-form-textarea>
							<div v-if="$v.form.message.$error" class="invalid-feedback">
								<span v-if="!$v.form.message.required">Le message est requis.</span>
							</div>
						</b-form-group>
						<div class="text-center w-100 mt-1 mb-2" v-show="loaded">
							<b-spinner variant="success" />
						</div>
						<div class="d-flex justify-content-between">
							<b-button type="reset" variant="outline-success" :disabled="loaded">Annuler</b-button>
							<b-button type="submit" variant="outline-success" :disabled="loaded">Envoyer</b-button>
						</div>
					</b-form>
				</b-col>
			</b-row>
			<b-row class="h-100 mt-3">
				<b-col>
					<div id="map">
						<MglMap :accessToken="mapboxAccessToken" :mapStyle.sync="mapStyle" :center="center"
							:zoom="zoom">
							<MglMarker :coordinates="coordinates" color="#006435" />
						</MglMap>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script lang="js">
import { required, email } from "vuelidate/lib/validators";
import { MglMap, MglMarker, MglPopup } from 'vue-mapbox'
import 'mapbox-gl/dist/mapbox-gl.css'
export default {
	components: {
		MglMap,
		MglMarker,
		MglPopup
	},
	data() {
		return {
			// mapboxAccessToken: "pk.eyJ1Ijoic2lkaWtoMTJyIiwiYSI6ImNsYWlodWFoaDAzMzgzcHMycjFjN2Rjc3UifQ.nQg4YOkFo3Ppy6k0cGm0KQ",
			translate: {
				countrySelectorLabel: "Code",
				countrySelectorError: "Choisir un pays",
				phoneNumberLabel: "Numéro de téléphone",
				example: "Exemple :",
			},

			mapboxAccessToken: 'pk.eyJ1Ijoic2lkaWtoMTJyIiwiYSI6ImNrc3I2MWZobTBqcGUybnRmZjEzZ2lmMDcifQ.8xB3LOhamnPAfz7nYcePEg',
			mapStyle: 'mapbox://styles/mapbox/streets-v11',
			center: [2.4224457, 6.3505816],
			zoom: 16,
			coordinates: [2.4224457, 6.3505816],
			loaded: false,
			form: {
				name: null,
				surname: null,
				email: null,
				phone: null,
				phoneNumber: null,
				object: null,
				motif: null,
				message: null,
			},

			motifOptions: [{ value: null, text: "Choisir le motif" }, { value: 'Motif 1', text: "Motif 1" }, { value: 'Motif 2', text: "Motif 2" }, { value: 'Motif 3', text: "Motif 3" }],
		};
	},
	computed: {
		dark() {
			if (window.localStorage.getItem("vuexy-skin") === "dark") return true;
			return false;
		},
	},
	validations: {
		form: {
			email: {
				required,
				email,
			},
			name: {
				required,
			},
			surname: {
				required,
			},
			phoneNumber: {
				required,
			},
			object: {
				required,
			},
			motif: {
				required,
			},
			message: {
				required,
			},
		},
	},
	methods: {
		update(event) {
			if (event) {
				this.form.phoneNumber = event.formattedNumber;
			}
		},
		formatter(value) {
			return value.toUpperCase();
		},
		toLower(value) {
			return value.toLowerCase();
		},
		onReset() {
			resetForm()
		},

		onSubmit() {
			this.$v.$touch();
			if (this.$v.$anyError) {
				return;
			}
			this.loaded = true;
			const data = {
				name: this.form.name + ' ' + this.form.surname,
				telephone: this.form.phoneNumber,
				email: this.form.email,
				motif: this.form.motif,
				object: this.form.object,
				message: this.form.message,
			}
			this.$http
				.post('/api/contact/help', data)
				.then(() => {
					this.loaded = false
					this.resetForm()
					this.$bvToast.toast(`Message envoyé avec succès`, {
						title: `Contact`,
						toaster: 'b-toaster-top-right',
						variant: "success",
						solid: true,
						appendToast: false
					})
				})
				.catch(() => {
					this.loaded = false
					this.$bvToast.toast(`Une erreur est survenue lors de l'envoi`, {
						title: `Contact`,
						toaster: 'b-toaster-top-right',
						variant: "danger",
						solid: true,
						appendToast: false
					})
				})
		},
		resetForm() {
			this.form = {
				name: null,
				surname: null,
				email: "",
				message: null,
				object: null,
				phoneNumber: null,
				phone: null,
				motif: null,
			}
			this.$nextTick(() => {
				this.$v.$reset()
			})
		},
	},
};
</script>
<style lang="scss" scoped>
#map {
	min-height: 80vh;
	height: 100%;
	width: 100%;
}
</style>