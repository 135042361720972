<template>
  <div>
    <b-container>
      <b-row class="h-100">
        <b-col>
          <div class="text-center">
            <h1 class="text-success font-weight-bold pt-3 page-title">
             Transit
            </h1>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-5">
      <b-row>
        <b-col sm="12" lg="8" md="12" >
          <p class="text-justify">
            La SOBEMAP effectue pour le compte de ses clients, les opérations de transit
import/export au port de Cotonou, à l’aéroport et aux frontières terrestres. Elle
assure également, la sécurité dans l’enlèvement des marchandises de ses clients
et leur évite des surcoûts.          </p>
        </b-col>
        <b-col sm="1" hidden-sm class="w-0 h-100"></b-col>
        <b-col class="sm-4 ">
          <prestations-nav />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PrestationsNav from "../../../containers/website/Navigation/PrestationsNav";
export default {
  components: { PrestationsNav },
};
</script>